<template>
  <f7-page name="home" @page:init="pageInit">
    <f7-navbar large>
      <f7-nav-title sliding>Pelikánec</f7-nav-title>
      <f7-nav-title-large>Ahoj, {{ userName }}</f7-nav-title-large>
      <f7-nav-right v-if="user?.prefs?.organization">
        <p class="company margin-right">{{ user.prefs.organization }}</p>
      </f7-nav-right>
    </f7-navbar>

    <f7-card
      v-if="cardMissingReservations.length"
      class="danger-bg cursor-pointer animate__animated animate__bounceIn"
      @click="missingCardsPopup = true"
    >
      <f7-card-header class="bold display-block">
        <span class="title display-block">Nevrátili ste parkovaciu kartu!</span>
        <span class="display-block subtitle">Pre viac informácii kliknite na túto dlaždicu.</span>
      </f7-card-header>
    </f7-card>

    <f7-card>
      <f7-card-header class="margin-bottom-half">
        <span class="title">Parkovanie v Eurovei</span>
        <f7-icon ios="f7:car_fill" md="material:garage" />
      </f7-card-header>
      <f7-card-content :class="{ 'text-align-center': !isParkingServiceLoaded }">
        <f7-preloader v-if="!isParkingServiceLoaded" color="primary" />
        <section v-else>
          <f7-button popup-open=".parking-reservation-form" large tonal color="secondary"
            >Rezervovať si parkovanie</f7-button
          >
          <f7-list class="margin-top-half" strong inset dividers-ios>
            <f7-list-item link="/parking/reservations/">Moje rezervácie</f7-list-item>
          </f7-list>
        </section>
      </f7-card-content>
    </f7-card>

    <f7-block-title>Ďalšie informácie</f7-block-title>
    <f7-list strong inset dividersIos>
      <f7-list-item link="/about/" title="O aplikácii"></f7-list-item>
    </f7-list>

    <template #fixed>
      <Menu />
    </template>

    <MissingCards v-if="cardMissingReservations.length" v-model:opened="missingCardsPopup" />
  </f7-page>
</template>

<script setup lang="ts">
import 'animate.css'
import { ref, computed } from 'vue'
import { useStore } from 'framework7-vue'
import { globalAppError } from '../assets/composables/utils'

import Menu from '../components/menu.vue'
import Reservations from '../assets/classes/parking/Reservations'
import MissingCards from '../components/parking/popups/MissingCards.vue'

const user = useStore('user')
const cardMissingReservations = useStore('cardMissingReservations')

const userName = computed<string>(() => user?.value?.name?.split(' ')[0])
const isParkingServiceLoaded = ref<boolean>(false)
const missingCardsPopup = ref<boolean>(false)

const pageInit = () => {
  Reservations.fetchNewData()
    .catch(() => {
      globalAppError()
    })
    .finally(() => {
      isParkingServiceLoaded.value = true
    })
}
</script>

<style scoped>
.title {
  font-weight: bold;
}

.company {
  font-size: 0.8em;
  letter-spacing: 0.6px;
  color: #333;
}

.subtitle {
  font-size: 0.8em;
}
</style>
