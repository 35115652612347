import HomePage from '../pages/index.vue'
import AboutPage from '../pages/about.vue'
import ParkingReservationPage from '../pages/parking/reservations.vue'
import ProfilePage from '../pages/profile.vue'

import NotFoundPage from '../pages/404.vue'

const routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/parking/reservations/',
    component: ParkingReservationPage,
  },
  {
    path: '/profile/',
    component: ProfilePage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
]

export default routes
