<template>
  <f7-page name="settings">
    <f7-navbar>
      <f7-nav-title>Profil</f7-nav-title>
      <f7-nav-right v-if="user?.prefs?.photo" class="profile-image__wrapper">
        <img :src="user.prefs.photo" alt="Pelikánec" class="margin-right-half profile-image__content" />
      </f7-nav-right>
    </f7-navbar>

    <f7-block-title>Prihlásený používateľ</f7-block-title>
    <f7-list strong-ios dividers-ios outline-ios>
      <f7-list-input :value="user?.name" label="Meno" type="text" disabled></f7-list-input>

      <f7-list-input :value="user?.email" label="E-mail" type="email" disabled></f7-list-input>

      <f7-list-input
        :value="user?.registration ? [user.registration] : [DateTime.now()]"
        label="Dátum registrácie"
        type="datepicker"
        :calendar-params="{ locale: 'sk' }"
        disabled
      ></f7-list-input>
    </f7-list>
    <f7-block strong-ios outline-ios>
      <p v-if="!isProduction">
        <f7-button color="green" @click="logAuthorizationToken">Generate JWT Token to console</f7-button>
        <f7-button color="green" @click="logGoogleAPIToken">Generate Google API JWT Token to console</f7-button>
      </p>
      <p>
        <f7-button fill large color="red" @click="logout">Odhlásiť sa</f7-button>
      </p>
    </f7-block>
    <f7-block class="text-align-center">
      <small>Verzia: {{ version }}</small>
    </f7-block>
  </f7-page>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'framework7-vue'
import { getDevice } from 'framework7'
import { DateTime } from 'luxon'

import { getCurrentSession, getAuthorizationToken, useLogout } from '../assets/composables/auth'
import type { User } from '../assets/composables/types'

const logout = useLogout()

const isProduction = import.meta.env.PROD
const user = computed<User | null>(() => useStore('user').value)
const version = ref('')
const device = getDevice()

const logAuthorizationToken = async () => {
  console.log(await getAuthorizationToken())
}

const logGoogleAPIToken = async () => {
  console.log((await getCurrentSession()).providerAccessToken)
}

onMounted(() => {
  version.value = __APP_VERSION__
})
</script>

<style scoped lang="scss">
.profile-image {
  &__wrapper {
    height: 80%;
    width: auto;
  }

  &__content {
    width: auto;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 0 2px #fff;
  }
}
</style>
