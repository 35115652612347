import { Preferences } from '@capacitor/preferences'
import type { Models } from 'appwrite'
import { getDevice } from 'framework7'
import { f7 } from 'framework7-vue'
import { DateTime } from 'luxon'

import store from '../../js/store'
import {
  createEmailPasswordSession,
  createSession,
  createJWT,
  createOAuth2Token,
  deleteSession,
  getAccount,
  getDefaultSettings,
  getSession,
  setProfilePrefs,
  updateSession,
} from './endpoints'
import { clearStore, globalAppError, notify, processError } from './utils'

const device = getDevice()

/*
 * This function updates and retrieves the user's account preferences if it's their first login after a logout,
 * otherwise it simply returns the current account details.
 */
const getProfilePrefs = async (account: Models.User<any>) => {
  let isFirstLogin: undefined | boolean = undefined
  const { value } = await Preferences.get({ key: 'PK_FIRST_LOGIN' })
  isFirstLogin = value !== 'false'
  if (isFirstLogin) {
    try {
      await setProfilePrefs()
      await Preferences.set({
        key: 'PK_FIRST_LOGIN',
        value: 'false',
      })
      return await getAccount(device.cordova)
    } catch (e) {
      processError(e, 'Nepodarilo sa načítať vaše údaje z nášho systému. Prosím, skúste to opäť neskôr.')
      await logoutWithoutPrompt()
    }
  }
  return account
}

export const getCurrentSession = async () => {
  return await getSession('current', device.cordova)
}

/*
 * This function authenticates the user by creating an Email/OAuth2 session with the given hostname.
 * It's run only when user clicks on the "Login" button.
 */
export const authenticate = async (hostname?: string, isOAuth: boolean = true, loginData?: any) => {
  f7.preloader.show()

  try {
    await getCurrentSession()
  } catch (e) {
    try {
      isOAuth
        ? await createOAuth2Token(undefined, hostname, device.cordova)
        : await createEmailPasswordSession(loginData.email, loginData.password, device.cordova)
      if (!isOAuth) {
        await Preferences.set({
          key: 'PK_FIRST_LOGIN',
          value: 'false',
        })
      }
      await setActiveUserIfAny()
    } catch (e) {
      f7.dialog.alert(e.message)
    }
  } finally {
    f7.preloader.hide()
  }
}

export const getAuthorizationToken = async (): Promise<string> => {
  const now = DateTime.now()
  const { value: token } = await Preferences.get({ key: 'PK_JWT_TOKEN' })
  let {
    value: tokenLastGeneration,
  }: {
    value: null | string | DateTime
  } = await Preferences.get({ key: 'PK_JWT_TOKEN_LAST_GENERATION' })

  if (tokenLastGeneration) {
    tokenLastGeneration = DateTime.fromISO(tokenLastGeneration)
  }

  if (
    token &&
    tokenLastGeneration instanceof DateTime &&
    tokenLastGeneration.plus({ minutes: 15 }) > now &&
    tokenLastGeneration < now
  ) {
    return token
  }

  try {
    const { jwt } = await createJWT(device.cordova)
    await Preferences.set({
      key: 'PK_JWT_TOKEN',
      value: jwt,
    })
    await Preferences.set({
      key: 'PK_JWT_TOKEN_LAST_GENERATION',
      value: now.toISO(),
    })
    return jwt
  } catch (e) {
    processError(e, 'Nepodarilo sa získať autorizačný token. Prosím, skúste to opäť neskôr.')
    return ''
  }
}

export const setActiveUserIfAny = async (): Promise<boolean> => {
  try {
    let user = await getAccount(device.cordova)

    try {
      /**
       * DISABLE UNTIL THIS ISSUE WILL BE FIXED:
       * https://github.com/appwrite/appwrite/issues/8206
       */

      /*user = await getProfilePrefs(user)*/
      const defaultSettings = await getDefaultSettings()

      await Promise.all([store.dispatch('setDefaultSettings', defaultSettings), store.dispatch('setUser', user)])
    } catch {
      globalAppError()
    }

    return true
  } catch (e) {
    const userId = (await Preferences.get({ key: 'oauth-userId' })).value
    const secret = (await Preferences.get({ key: 'oauth-secret' })).value

    if (userId && secret) {
      try {
        await createSession(userId, secret, device.cordova)
        return await setActiveUserIfAny()
      } catch {
        await store.dispatch('setUser', null)
        await Preferences.set({
          key: 'PK_FIRST_LOGIN',
          value: 'true',
        })
        return false
      }
    } else {
      await store.dispatch('setUser', null)
      await Preferences.set({
        key: 'PK_FIRST_LOGIN',
        value: 'true',
      })
      return false
    }
  }
}

export const logoutWithoutPrompt = async (notice = null): Promise<void> => {
  await Promise.all([deleteSession('current', device.cordova), clearStore(), Preferences.clear()])

  if (notice) {
    notify(notice)
  }
}

export const checkTokenValidity = async (forceReload: boolean = false): Promise<boolean> => {
  const session = await getSession('current', device.cordova)
  const expiration = DateTime.fromISO(session.expire)
  const now = DateTime.now()

  if (expiration < now || forceReload) {
    try {
      await updateSession('current', device.cordova)
    } catch (e) {
      await logoutWithoutPrompt('Platnosť tokenu vypršala. Prosím, prihláste sa znova.')
    }
    return false
  } else {
    return true
  }
}

export const useLogout = () => {
  return () => {
    f7.dialog.confirm('Naozaj sa chcete odhlásiť?', async () => {
      await logoutWithoutPrompt()
      notify('Boli ste úspešne odhlásený')
    })
  }
}
