<template>
  <f7-popup push @popup:opened="emit('popupOpened')" @popup:closed="emit('popupClosed')">
    <f7-page>
      <f7-navbar :title="title" large transparent :back-link="step > 1" @click:back="moveBack">
        <f7-nav-right>
          <f7-link popup-close>Zatvoriť</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <slot />
    </f7-page>
  </f7-popup>
</template>
<script setup lang="ts">
const emit = defineEmits(['popupOpened', 'popupClosed', 'stepChanged'])
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  step: {
    type: Number,
    default: 1,
  },
})

const moveBack = () => {
  emit('stepChanged', props.step - 1)
}
</script>
