import { DateTime } from 'luxon'
import { useStore } from 'framework7-vue'

import { ToReadableTime } from './mappers'
import { ParkingReservationSuccessEvent } from './types'

const umamiEvent = (eventName: string, data: object) => {
  // @ts-ignore
  umami.track(eventName, data)
}

export const trackParkingReservationSuccess = ({ email, date, partOfDay }: ParkingReservationSuccessEvent) => {
  umamiEvent('parking-reservation-success', {
    email: email || useStore('user').value.email,
    date: DateTime.fromJSDate(date).toISODate(),
    partOfDay: ToReadableTime(partOfDay, false),
  })
}
