import { f7, useStore } from 'framework7-vue'

import type { ReservationRich } from '../../composables/types'
import Reservations from './Reservations'
import store from '../../../js/store'
import { deleteReservation, returnCard } from '../../composables/endpoints'
import { notify, processError } from '../../composables/utils'

export default class Reservation extends Reservations {
  id: ReservationRich['id']
  date: ReservationRich['date']
  email: ReservationRich['email']
  partOfDay: ReservationRich['partOfDay']
  timelineDate: ReservationRich['timelineDate']
  cardReturned: ReservationRich['cardReturned']

  constructor(res: ReservationRich) {
    super()

    this.id = res.id
    this.date = res.date
    this.email = res.email
    this.partOfDay = res.partOfDay
    this.timelineDate = res.timelineDate
    this.cardReturned = res.cardReturned
  }

  removeCurrentResFromList() {
    const reservations: Reservation[] = useStore('reservations').value

    const newReservations = reservations.filter((reservation) => reservation.date !== this.date)
    store.dispatch('setReservations', newReservations)
  }

  setCardReturn(isReturned: boolean) {
    this.cardReturned = isReturned
    return true
  }

  async removeReservation() {
    f7.dialog.preloader('Ruším rezerváciu')
    try {
      await deleteReservation(this.id, this.cardReturned)
      this.removeCurrentResFromList()
      if (!this.cardReturned) {
        notify('Rezervácia bola úspešne zrušená. <b>Nezabudnite vrátiť parkovaciu kartu!</b>')
      } else {
        notify('Rezervácia bola úspešne zrušená.')
      }
    } catch (e) {
      processError(e)
      this.removeCurrentResFromList()
      await Reservations.fetchNewData()
    } finally {
      f7.dialog.close()
    }
  }

  async returnCard() {
    f7.dialog.preloader('Ukladám dáta do systému')
    try {
      await returnCard(this.id)
      Reservations.fetchNewData()
      notify('Karta bola úspešne vrátená.')
    } catch (e) {
      processError(e)
      Reservations.fetchNewData()
    } finally {
      f7.dialog.close()
    }
  }
}
