<template>
  <f7-page login-screen>
    <f7-login-screen-title>Prihlásenie</f7-login-screen-title>
    <f7-list>
      <f7-list-button
        title="Prihlásiť sa cez firemný Google účet"
        @click="auth(true)"
        color="secondary"
      ></f7-list-button>
      <f7-list-button
        v-if="!showLoginForm"
        title="Prihlásiť sa cez prihlasovací formulár"
        @click="showLoginForm = true"
        color="secondary"
      ></f7-list-button>
    </f7-list>
    <template v-if="showLoginForm">
      <f7-list form>
        <f7-list-input
          v-model:value="email"
          label="E-mail"
          type="email"
          placeholder="Váš e-mail"
          @keydown.enter="auth(false, $event.target)"
        />
        <f7-list-input
          v-model:value="password"
          label="Heslo"
          type="password"
          placeholder="Vaše heslo"
          @keydown.enter="auth(false, $event.target)"
        />
      </f7-list>
      <f7-list inset>
        <f7-list-button @click="auth(false)">Prihlásiť sa</f7-list-button>
      </f7-list>
    </template>
    <f7-list>
      <f7-block-footer>
        <p>Táto aplikácia je určená výlučne zamestnancom a externistom firiem pod správou Pelican Travel Group.</p>
      </f7-block-footer>
    </f7-list>
  </f7-page>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { f7 } from 'framework7-vue'

import { authenticate } from '../assets/composables/auth'

const hostname = ref<string>('')
const showLoginForm = ref<boolean>(false)

const email = ref<string>('')
const password = ref<string>('')

onMounted(() => {
  hostname.value = `${location.protocol}//${location.host}`
})

const auth = async (isOAuth: boolean, el?: HTMLElement) => {
  if (!isOAuth && (email.value === '' || password.value === '')) {
    f7.dialog.alert('Vyplňte prosím všetky polia.')
    return
  }

  if (el) {
    el.blur()
  }

  if (isOAuth) {
    await authenticate(hostname.value, isOAuth)
  } else {
    await authenticate(undefined, isOAuth, { email: email.value, password: password.value })
  }

  email.value = ''
  password.value = ''
}
</script>
