<template>
  <f7-list form strong-ios dividers-ios outline-ios @submit.prevent="goToSummary">
    <f7-list-input
      v-model:value="selectedDate"
      error-message="Toto pole je povinné"
      :error-message-force="validation.datePicker"
      type="datepicker"
      label="Dátum parkovania"
      placeholder="Vyberte dátum"
      readonly
      required
      :calendar-params="calendarParams"
      @calendar:change="onDayClick"
    />
    <f7-list-input
      :value="formattedTime"
      input-id="timePicker"
      error-message="Toto pole je povinné"
      :error-message-force="validation.timePicker"
      type="text"
      label="Čas parkovania"
      :placeholder="isFetchingSlots ? 'Načítavam dostupné časy...' : 'Vyberte časový slot'"
      readonly
      required
      :disabled="isFetchingSlots || !availableTimeSlots.values.length"
      :info="!availableTimeSlots.values.length ? 'Čas bude možné vybrať až po zvolení dátumu' : undefined"
      @click="selectedTime = selectedTime || availableTimeSlots.values[timePicker.cols[0]?.activeIndex || 0]"
      @change="(e) => (selectedTime = e.target.value)"
    />
    <f7-block>
      <f7-button large tonal color="secondary" type="submit">Pokračovať na sumár</f7-button>
    </f7-block>
  </f7-list>
</template>

<script setup lang="ts">
import { f7, useStore } from 'framework7-vue'
import { DateTime } from 'luxon'
import { inject, onMounted, reactive, ref, watch } from 'vue'

import { getAvailableSlots } from '../../../assets/composables/endpoints'
import { ToReadableTime } from '../../../assets/composables/mappers'
import type { AvailableSlots, PartOfDay } from '../../../assets/composables/types'
import { notify } from '../../../assets/composables/utils'

const {
  currentDate,
  selectedDate,
  selectedDateWithStaticTimezone,
  selectedTime,
  formattedTime,
  validation,
  validateForm,
} = inject<any>('formData')

let timePicker

const emit = defineEmits(['continue'])
const props = defineProps({
  removeInnerData: {
    type: Boolean,
    default: false,
  },
})

const isFetchingSlots = ref(false)
const calendarParams = ref({
  locale: 'sk-SK',
  dateFormat: { weekday: 'long', month: 'long', day: '2-digit', year: 'numeric' },
  minDate: currentDate.value.startOf('day').toString(),
  maxDate: currentDate.value
    .plus({ days: useStore('defaultSettings').value.maxAllowedResDate })
    .endOf('day')
    .toString(),
  yearSelector: false,
  monthPicker: false,
  closeOnSelect: true,
})

const availableTimeSlots = reactive({
  textAlign: 'center',
  rotateEffect: true,
  values: [] as PartOfDay[],
  displayValues: [] as string[],
})

onMounted(() => {
  timePicker = f7.picker.create({
    inputEl: '#timePicker',
    cols: [availableTimeSlots],
    renderToolbar: () => {
      return `
        <div class="toolbar">
          <div class="toolbar-inner">
            <div class="left"></div>
            <div class="right">
              <a href="#" class="link sheet-close popover-close">Vybrať</a>
            </div>
          </div>
        </div>
      `
    },
  })
})

const resetComponentBasedTimeData = () => {
  availableTimeSlots.values = []
  availableTimeSlots.displayValues = []
}

const isDateAvailable = async (date: DateTime): Promise<AvailableSlots> => {
  const slots: AvailableSlots = await getAvailableSlots(date)

  if (!slots.isAnyAvailable) {
    selectedDate.value = []
    notify(
      `Pre zvolený dátum <b>${date.toFormat('dd. MM (cccc)')}</b> už nie sú dostupné žiadne časové sloty. Vyberte si prosím iný dátum.`,
    )
    return
  }

  return slots
}

const onDayClick = async (calendar: Date[]) => {
  if (calendar.length === 0) return
  const date = DateTime.fromJSDate(selectedDateWithStaticTimezone.value[0])
  resetComponentBasedTimeData()

  try {
    isFetchingSlots.value = true
    const slots = await isDateAvailable(date)
    if (slots) {
      availableTimeSlots.values = slots.values
      availableTimeSlots.displayValues = slots.displayValues
    }
    if (!availableTimeSlots.values.includes(selectedTime.value)) {
      selectedTime.value = null
    }
  } catch (error) {
    selectedDate.value = []
    notify('Nepodarilo sa zistiť dostupnosť dátumu. Skúste to prosím neskôr.')
  } finally {
    isFetchingSlots.value = false
  }
}

const goToSummary = () => {
  if (validateForm()) {
    emit('continue')
  }
}

watch(selectedTime, (newValue: PartOfDay) => {
  formattedTime.value = ToReadableTime(newValue, false)
})

watch(
  () => props.removeInnerData,
  (newValue: boolean) => {
    if (newValue) {
      resetComponentBasedTimeData()
    }
  },
)
</script>
