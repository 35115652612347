import { Network } from '@capacitor/network'
import { PushNotifications } from '@capacitor/push-notifications'
import * as Sentry from '@sentry/vue'
import { getDevice } from 'framework7'
import { f7 } from 'framework7-vue'

import store from '../../js/store'
import { logoutWithoutPrompt } from './auth'
import { ErrorCodes } from './types'

const device = getDevice()

const captureException = (error: any, code: string, message: string): void => {
  Sentry.captureException(error, (scope) => {
    return scope.setContext('Enriched Error Context', {
      'Context': error,
      'Code from BE': code,
      'Message': message,
    })
  })
}

export const oauthScopes: string[] = [
  'https://www.googleapis.com/auth/calendar',
  'https://www.googleapis.com/auth/user.organization.read',
  'https://www.googleapis.com/auth/user.birthday.read',
]

export const clearStore = async (): Promise<void> => {
  await Promise.all([
    store.dispatch('setUser', null),
    store.dispatch('setDefaultSettings', {}),
    store.dispatch('setReservations', []),
    store.dispatch('setHistoricalReservations', []),
  ])
}

export const notify = (text: string, callback?: () => void): void => {
  f7.dialog.alert(text, callback)
}

export const globalAppError = () => {
  f7.dialog.alert(
    `
      Nastala chyba v spojení so serverom. Z bezpečnostných dôvodov sme Vás automaticky odhlásili. Prosím, skúste sa prihlásiť znova. Ak problémy pretrvávajú, kontaktujte Slack channel
      <a class="link external" href="https://pelichat.slack.com/archives/C07QL4CMB" target="_blank">#technicka-podpora</a>. 
    `,
    'Chyba pri spustení aplikácie',
    () => logoutWithoutPrompt(),
  )
}

export const processError = (error: any, customMessage?: string): void => {
  const customErrorCode: ErrorCodes | null = error.response?._data?.data?.code || null
  let message: string = ''

  if (customErrorCode) {
    switch (customErrorCode) {
      case ErrorCodes.noAvailableSlots:
        message =
          'Bohužiaľ sa medzičasom všetky dostupné parkovacie kapacity obsadili. Vyberte si prosím iný čas alebo dátum.'
        break
      case ErrorCodes.sameTimeReservation:
        message = 'Rezervácia s rovnakým dátumom a časovým úsekom už existuje v systéme. Žiadne zmeny neboli vykonané.'
        break
      case ErrorCodes.reservationsNotAllowed:
        message = 'Rezervácie momentálne nie sú povolené.'
        break
      case ErrorCodes.reservationsLimitReached:
        message = 'Bohužiaľ ste dosiahli limit rezervácií na tento mesiac. Vašu rezerváciu nie je možné vytvoriť.'
        break
      case ErrorCodes.resInPast:
        message = 'Bohužiaľ, nie je možné vytvoriť ani zrušiť rezerváciu s časom v minulosti.'
        break
      case ErrorCodes.resInProgress:
        message = 'Bohužiaľ, nie je možné zrušiť rezerváciu, ktorá práve prebieha.'
        break
      case ErrorCodes.todayAlldayExits:
        message =
          'Pre dnešný deň ste už vytvorili celodennú rezerváciu. Keďže dnešný deň už prebieha, nie je možné ju nahradiť inou rezerváciou.'
        break
      case ErrorCodes.badPartOfDayToday:
        message = 'Ak chcete rezerváciu doplniť o ostatnú časť dňa, vyberte si prosím celodennú rezerváciu.'
        break
      case ErrorCodes.existingResNewNotCreated:
        message =
          'Nastala chyba pri vytváraní novej rezervácie. Bohužiaľ sme ale stihli odstrániť a vyresetovať pôvodnú rezerváciu. Skúste to prosím znova alebo kontaktujte podporu.'
        captureException(error, ErrorCodes.existingResNewNotCreated, customMessage || message)
    }
  } else {
    message = 'Niekde nastala chyba. Skúste to prosím neskôr.'
    captureException(error, customErrorCode, customMessage || message)
  }

  notify(customMessage || message)
}

export const initNetworkListeners = () => {
  const message = 'Stratili ste pripojenie k internetu. Skontrolujte svoje pripojenie.'

  Network.getStatus().then((status) => {
    if (!status.connected) {
      f7.dialog.preloader(message)
    }
  })

  Network.addListener('networkStatusChange', (status) => {
    if (status.connected) {
      f7.dialog.close()
    } else {
      f7.dialog.preloader(message)
    }
  })
}

export const initPushNotifications = () => {
  if (!device.cordova) return

  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermissions().then((result) => {
    if (result.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register()
    } else {
      // Show some error
    }
  })

  /*PushNotifications.addListener('registration', (token: Token) => {
    alert('Push registration success, token: ' + token.value)
  })

  PushNotifications.addListener('registrationError', (error: any) => {
    alert('Error on registration: ' + JSON.stringify(error))
  })*/

  /*PushNotifications.addListener(
    'pushNotificationReceived',
    (notification: PushNotificationSchema) => {
      alert('Push received: ' + JSON.stringify(notification))
    },
  )

  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (notification: ActionPerformed) => {
      alert('Push action performed: ' + JSON.stringify(notification))
    },
  )*/
}
