<template>
  <ModalFormWrapper
    title="Rezervačný formulár"
    swipe-to-close
    :step="step"
    @popup-opened="initData"
    @popup-closed="resetComponentData"
    @step-changed="(e) => (step = e)"
  >
    <FirstStep v-if="step === 1" @continue="step++" :remove-inner-data="removeInnerData" />
    <SecondStep
      v-if="step === 2"
      @submit="Reservations.makeReservation(selectedDateWithStaticTimezone[0], selectedTime)"
    />
  </ModalFormWrapper>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon'
import { computed, provide, ref } from 'vue'

import Reservations from '../../../assets/classes/parking/Reservations'
import { ToReadableTime } from '../../../assets/composables/mappers'
import { useParkingReservationData } from '../../../assets/composables/parking/reservationData'
import type { SelectedSlotData } from '../../../assets/composables/types'

import ModalFormWrapper from '../../wrappers/ModalFormWrapper.vue'
import FirstStep from './firstStep.vue'
import SecondStep from './secondStep.vue'

const {
  currentDate,
  step,
  selectedDate,
  selectedDateWithStaticTimezone,
  selectedTime,
  formattedTime,
  validation,
  validateForm,
  resetData,
} = useParkingReservationData()

const removeInnerData = ref<boolean>(false)
const formattedValues = computed<SelectedSlotData>(() => ({
  date: selectedDate.value[0]
    ? DateTime.fromJSDate(selectedDateWithStaticTimezone.value[0]).toFormat('dd. MM. yyyy (cccc)')
    : '',
  time: ToReadableTime(selectedTime.value),
}))

const initData = () => {
  removeInnerData.value = false
  currentDate.value = DateTime.now()
}

const resetComponentData = () => {
  removeInnerData.value = true
  resetData()
}

provide('formData', {
  currentDate,
  selectedDate,
  selectedDateWithStaticTimezone,
  selectedTime,
  formattedTime,
  validation,
  validateForm,
  formattedValues,
})
</script>
