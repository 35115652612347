<template>
  <f7-page name="reservations" ptr @ptr:refresh="updateData" @page:init="pageInit" @page:beforeremove="removeTooltips">
    <f7-navbar title="Moje rezervácie parkovania" back-link></f7-navbar>

    <f7-block-title
      >Dnešná rezervácia – <b>{{ formattedToday }}</b></f7-block-title
    >
    <Timeline v-if="todayReservations.length" :data="todayReservations" hide-timeline-date disable-card-return />
    <f7-block strong inset v-else>
      <p class="text-align-center">Na dnes nemáte naplánovanú žiadnu rezerváciu.</p>
    </f7-block>
    <f7-block>
      <f7-segmented strong tag="p">
        <f7-button :active="activeSegmentedButton === 0" @click="displaySection(0)">Naplánované rezervácie</f7-button>
        <f7-button :active="activeSegmentedButton === 1" @click="displaySection(1)">Historické rezervácie</f7-button>
      </f7-segmented>
    </f7-block>
    <section v-show="activeSegmentedButton === 0">
      <f7-block-title
        >Naplánované rezervácie
        <f7-icon class="upcoming-reservations__tooltip" ios="f7:info_circle_fill" md="material:info" :size="20" />
      </f7-block-title>
      <Timeline :data="upcomingReservations" no-data-text="Nemáte žiadne naplánované rezervácie." disable-card-return />
    </section>
    <section v-show="activeSegmentedButton === 1">
      <f7-block-title
        >Historické rezervácie
        <f7-icon class="historic-reservations__tooltip" ios="f7:info_circle_fill" md="material:info" :size="20" />
      </f7-block-title>
      <Timeline
        :data="historicalReservations"
        no-data-text="V minulosti ste ešte nevytvorili žiadnu rezerváciu."
        disable-delete
      />
    </section>

    <template #fixed>
      <Menu />
    </template>
  </f7-page>
</template>

<script setup lang="ts">
import { f7 } from 'framework7-vue'
import { ref, computed } from 'vue'
import { DateTime } from 'luxon'

import Timeline from '../../components/parking/Timeline.vue'
import Menu from '../../components/menu.vue'
import Reservations from '../../assets/classes/parking/Reservations'
import type Reservation from '../../assets/classes/parking/Reservation'

const today = ref(DateTime.now())

const reservations = computed(() => Reservations.listUpcomingReservations())
const todayReservations = computed(() =>
  reservations.value.filter((res: Reservation) => res.date?.toISODate() === DateTime.now().toISODate()),
)
const upcomingReservations = computed(() => reservations.value.filter((res: Reservation) => res.date > DateTime.now()))
const historicalReservations = computed(() => Reservations.listHistoricalReservations())
const formattedToday = computed(() => today.value.toFormat('dd. MM. yyyy'))

const activeSegmentedButton = ref(0)

const displaySection = (section: number) => {
  activeSegmentedButton.value = section
}

const pageInit = () => {
  f7.tooltip.create({
    targetEl: '.upcoming-reservations__tooltip',
    text: 'Ak chcete nejakú rezerváciu odstrániť, stačí po nej posunúť zprava doľava a kliknúť na tlačidlo "Vymazať".',
  })

  f7.tooltip.create({
    targetEl: '.historic-reservations__tooltip',
    text: 'Zobrazujú sa rezervácie za posledné 3 mesiace. Tieto rezervácie už nie je možné odstrániť.',
  })
}

const removeTooltips = () => {
  f7.tooltip.destroy('.upcoming-reservations__tooltip')
  f7.tooltip.destroy('.historic-reservations__tooltip')
}

const updateData = async (done: () => void) => {
  today.value = DateTime.now()
  await Reservations.fetchNewData()
  done()
}
</script>
