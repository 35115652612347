<template>
  <f7-popup class="demo-popup">
    <f7-page>
      <f7-navbar title="Nevrátená parkovacia karta">
        <f7-nav-right>
          <f7-link popup-close>Zatvoriť</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-block>
        <p>
          Podľa pravidiel parkovania je potrebné vrátiť parkovaciu kartu
          <b>do {{ defaultSettings.hoursFrameReturnCard }} hodín</b> od ukončenia parkovania.
        </p>
        <p>
          Akonáhle kartu vrátite, choďte do časti
          <code>Moje rezervácie | Historické rezervácie</code>, posuňte prstom rezerváciu doľava a zvoľte možnosť
          <code>Vrátiť kartu</code>.
        </p>
      </f7-block>
      <f7-block>
        <f7-block-title>Zoznam rezervácií s nevrátenou kartou</f7-block-title>
        <div class="data-table">
          <table>
            <thead>
              <tr>
                <th class="label-cell">Dátum rezervácie</th>
                <th class="label-cell">Časť dňa</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="res in reservations" :key="res.id">
                <td class="label-cell">{{ res.date?.toFormat('dd. MM. yyyy') }}</td>
                <td class="label-cell">{{ res.partOfDay.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script setup lang="ts">
import { useStore } from 'framework7-vue'
import { computed } from 'vue'

import type { DefaultSettings, ReservationRich } from '../../../assets/composables/types'

const defaultSettings = computed<DefaultSettings>(() => useStore('defaultSettings').value)
const reservations = useStore('cardMissingReservations') as ReservationRich[]
</script>
