import { DateTime } from 'luxon'

import type { ReservationData, ReservationRich } from './types'
import { PartOfDay } from './types'

export const ToReservation = (reservation: ReservationData): ReservationRich => {
  return {
    id: reservation.id,
    email: reservation.email,
    date: DateTime.fromISO(reservation.date),
    timelineDate: {
      day: DateTime.fromISO(reservation.date).toFormat('dd'),
      month: (DateTime.fromISO(reservation.date).toFormat('LLL')).toUpperCase(),
    },
    partOfDay: {
      name: ToReadableTime(reservation.partOfDay, false),
      range: ToTimeRange(reservation.partOfDay),
    },
    cardReturned: reservation.cardReturned,
  }
}

const ToTimeRange = (time: PartOfDay): string[] => {
  // In case of need to change the time range, don't forget to change it on BE side, too
  switch (time) {
    case PartOfDay.morning:
      return ['00:00', '11:59']
    case PartOfDay.afternoon:
      return ['12:00', '23:59']
    case PartOfDay.allday:
      return ['00:00', '23:59']
    default:
      return []
  }
}

export const ToReadableTime = (time: PartOfDay, extended: boolean = true): string => {
  switch (time) {
    case PartOfDay.morning:
      return 'Dopoludnie' + (extended ? ` ${ToTimeRange(time).join(' – ')}` : '')
    case PartOfDay.afternoon:
      return 'Popoludnie' + (extended ? ` ${ToTimeRange(time).join(' – ')}` : '')
    case PartOfDay.allday:
      return 'Celý deň' + (extended ? ` ${ToTimeRange(time).join(' – ')}` : '')
    default:
      return ''
  }
}
