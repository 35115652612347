import { createStore } from 'framework7/lite'
import * as Sentry from '@sentry/vue'

import type { DefaultSettings, StoreState, User } from '../assets/composables/types'
import type Reservation from '../assets/classes/parking/Reservation'

const store = createStore({
  state: {
    defaultSettings: {},
    isUserLoggedIn: false,
    reservations: [],
    historicalReservations: [],
    cardMissingReservations: [],
    user: null,
  } as StoreState,

  getters: {
    user({ state }: { state: StoreState }) {
      return state.user
    },
    isUserLoggedIn({ state }: { state: StoreState }) {
      return state.isUserLoggedIn
    },
    defaultSettings({ state }: { state: StoreState }) {
      return state.defaultSettings
    },
    reservations({ state }: { state: StoreState }) {
      return state.reservations
    },
    historicalReservations({ state }: { state: StoreState }) {
      return state.historicalReservations
    },
    cardMissingReservations({ state }: { state: StoreState }) {
      return state.cardMissingReservations
    },
  },

  actions: {
    setUser({ state }: { state: StoreState }, user: User) {
      Sentry.setUser(user)
      state.user = user
      state.isUserLoggedIn = !!user
    },
    setDefaultSettings({ state }: { state: StoreState }, settings: DefaultSettings) {
      state.defaultSettings = settings
    },
    setReservations({ state }: { state: StoreState }, reservations: Reservation[]) {
      state.reservations = reservations
    },
    setHistoricalReservations({ state }: { state: StoreState }, reservations: Reservation[]) {
      state.historicalReservations = reservations
    },
    setCardMissingReservations({ state }: { state: StoreState }, reservations: Reservation[]) {
      state.cardMissingReservations = reservations
    },
  },
})
export default store
