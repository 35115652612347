<template>
  <f7-page name="about">
    <f7-navbar title="O aplikácii" back-link="Späť"></f7-navbar>
    <f7-block-title>O aplikácii "Pelikánec"</f7-block-title>
    <f7-block>
      <p>
        Vitaj v aplikácii Pelikánec, ktorá momentálne predstavuje nástroj pre rezerváciu parkovacích miest v nákupnom
        centre Eurovea. Táto aplikácia je výlučne k dispozícii pre zamestnancov spoločnosti pelicantravel.com s.r.o.
      </p>

      <p>
        Po úspešnom prihlásení do aplikácie môžeš systematicky monitorovať dostupnosť voľných parkovacích miest pre
        konkrétny dátum, ktorý si sám vyberieš. S možnosťou rezervácie až 2 týždne vopred máš istotu, že budeš mať k
        dispozícii parkovacie miesto presne v tom čase, kedy to budeš potrebovať. Spomedzi časových intervalov si môžeš
        zvoliť doobednú, poobednú alebo celodennú rezerváciu.
      </p>

      <p>
        Okrem toho môžeš sledovať históriu Tvojich predchádzajúcich rezervácií, čím Ti aplikácia poskytuje prehľad o
        počte rezervácií ktoré si v minulosti vytvoril. V prípade zmeny plánov môžeš jednoducho odstrániť naplánované
        rezervácie, čím máš maximálnu flexibilitu a zároveň umožníš iným používateľom rezervovať si parkovacie miesto ad
        hoc.
      </p>
    </f7-block>
    <f7-block-title>
      Pravidlá pre parkovanie na zdieľaných parkovacích miestach v nededikovanej parkovacej zóne B3 (modrá) Eurovea
    </f7-block-title>
    <f7-list strong outline-ios dividers-ios inset-md accordion-list>
      <f7-list-item accordion-item title="Ako postupovať pri rezervácii">
        <f7-accordion-content>
          <f7-block>
            <p>
              <ol>
                <li>
                  Rezerváciu parkovania vytvoríš kliknutím na zelené tlačidlo “+” vpravo dole alebo cez rámček
                  <b>“REZERVOVAŤ SI PARKOVANIE”</b> na úvodnej obrazovke.
                </li>
                <li>
                  Otvorí sa Ti rezervačný formulár kde si vyberieš <b>dátum a čas</b> parkovania. Čas parkovania môže byť:
                  <ul>
                    <li>celý deň</li>
                    <li>dopoludnie</li>
                    <li>alebo popoludnie</li>
                  </ul>
                  V zátvorke vidíš koľko miest je v ten deň aktuálne voľných. Po vybratí dátumu a času klikneš na
                  <b>“POKRAČOVAŤ NA SUMÁR”</b>.
                </li>
                <li>
                  Skontroluješ si ešte raz termín a čas rezervácie parkovania a klikneš na <b>“POTVRDIŤ REZERVÁCIU”</b>.
                  Po kliknutí sa Ti zobrazí okno s potvrdením, že rezervácia bola úspešne vytvorená.
                </li>
                <li>
                  Všetky svoje rezervácie (naplánované aj historické) si môžeš skontrolovať v záložke
                  <b>“Domov” → “Moje rezervácie”</b>
                </li>
                <li>
                  Parkovaciu kartičku si môžeš vziať <b>najskôr jeden deň pred plánovaným parkovaním</b> alebo v deň
                  plánovaného parkovania. Kartičku si vyzdvihneš <b>na stolíku pri veľkej tlačiarni na chodbe</b>.
                </li>
                <li>
                  <b>Na toto isté miesto ju najneskôr 48 hodín po ukončení parkovania vrátiš.</b> Až následne v aplikácii
                  vojdeš do záložky “Moje rezervácie” → “Historické rezervácie” → posunieš prstom rezerváciu doľava a
                  zvolíš možnosť <b>“Vrátiť kartu”</b>. Aplikácia sa ťa opýta či si kartu naozaj vrátil/a a zaklikneš
                  <b>“OK”</b>. Všetky vrátenia aj nevrátenia kariet sa zobrazujú v Backoffice appky, a v súlade s
                  rešpektom voči ostatným kolegom prosíme, o dodržiavanie pravidiel.
                </li>
                <li>Každý parkovací deň si volíš <b>samostatne</b>.</li>
              </ol>
            </p>
          </f7-block>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item accordion-item title="Pravidlá a zásady parkovania">
        <f7-accordion-content>
          <f7-block>
            <p>
              <ol>
                <li>Maximálny počet rezervácií parkovacieho miesta na zamestnanca <b>za mesiac je 7.</b></li>
                <li>
                  Parkovanie je zobrazené:
                  <ul>
                    <li><b>na celý deň</b> vždy od 00:00 - 23:59 ako celý 24 hodinový interval</li>
                    <li><b>na dopoludnie</b> 00:00 - 11:59</li>
                    <li><b>na popoludnie</b> 12.00 - 23:59</li>
                  </ul>
                </li>
                <li>Parkovanie si môžeš rezervovať maximálne 14 dní vopred.</li>
                <li>
                  <b>Vrátenie parkovacej karty je povinné najneskôr 48 hodín po ukončení parkovania</b>. Po vrátení karty
                  je povinné zakliknúť tento úkon aj v aplikácii.
                </li>
                <li>
                  Pre vjazd a výjazd cez rampu vždy prilož parkovaciu kartu k čítačke bez ohľadu na to, či je rampa
                  zatvorená alebo otvorená. Ak je rampa otvorená, prejdeš a nepriložíš kartu, nemusí ťa pustiť von ak sa
                  medzitým zase zatvorí.
                </li>
                <li>
                  Ak použiješ našu parkovaciu kartu na vjazd do podzemnej garáže (1. rampa)
                  <b
                    >musíš zaparkovať na vyhradených parkovacích miestach pre Offices - nededikovaná zóna B3 (modrá
                    farba)</b
                  >. Ak zaparkuješ na miestach pre verejnosť, bude nám Eurovea fakturovať 3€/h., bez možnosti uplatnenia 3
                  hodín parkovania grátis.
                </li>
                <li>Sankcia za stratu parkovacej karty je vo výške 50€.</li>
                <li>
                  V prípade voľných parkovacích miest na nasledujúci deň je možné rezervovať si
                  <b>“EXTRA” parkovanie</b> aj po maximálnom využití Tvojho mesačného limitu (teda 7x/mesiac). Toto EXTRA
                  parkovanie ja však možné si rezervovať najskôr o 18:00 pred dňom plánovaného parkovania. Toto nové
                  pravidlo nám zabezpečí, aby boli parkovacie miesta naplno využité. V tomto zmysle teda môžeš parkovať aj
                  30x/mesiac, ak si rezervuješ parkovanie večer. <br /><br />
                  <p>
                    <i>
                      <b>Dôležitá informácia:</b> Ak využiješ extra parkovanie, teda 6 hodín pred polnocou si ho
                      rezervuješ, kým ešte nemáš 7x parkovanie v mesiaci, počíta sa Ti to do Tvojho počtu/nároku na 7
                      parkovaní/mesiac.
                    </i>
                  </p>
                  <p>
                    <i>
                      <b>POZOR:</b> Aj polovica dňa, teda dopoludnie/popoludnie sa počíta ako 1 celý deň parkovania v
                      rámci mesačného limitu.
                    </i>
                  </p>
                </li>
              </ol>
            </p>
          </f7-block>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item accordion-item title="Vysvetlivky">
        <f7-accordion-content>
          <f7-block>
            <p>
              <ul>
                <li>
                  <b>Zdieľané parkovacie miesto:</b> parkovacie miesto, ktoré nie je pridelené konkrétnemu zamestnancovi
                </li>
                <li>
                  <b>Nededikovaná zóna:</b> vyhradená parkovacia zóna s rampu v ktorej môžete parkovať na ktoromkoľvek
                  parkovacom mieste, ktoré nie je obsadené
                </li>
                <li>
                  <b>Zóna B3(modrá) alebo parkovanie Office:</b> označenie parkovacej zóny na - 3. podzemnom podlaží (PP)
                </li>
              </ul>
            </p>
          </f7-block>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<style scoped lang="scss">
ul {
  list-style-type: disc;
  list-style-position: inside;
}
</style>
