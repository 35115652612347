import type { Models } from 'appwrite'
import type { DateTime } from 'luxon'

import type Reservation from '../classes/parking/Reservation'

type PartOfDayCount = {
  [key in PartOfDay]: number
}

export enum PartOfDay {
  morning = 'morning',
  afternoon = 'afternoon',
  allday = 'allday',
}

export enum ErrorCodes {
  noAvailableSlots = 'NO_AVAILABLE_SLOTS',
  sameTimeReservation = 'SAME_TIME_RESERVATION',
  reservationsNotAllowed = 'RESERVATIONS_NOT_ALLOWED',
  resInPast = 'RESERVATION_IN_PAST',
  resInProgress = 'RESERVATION_IN_PROGRESS',
  existingResNewNotCreated = 'EXISTING_RESERVATION_NEW_NOT_CREATED',
  todayAlldayExits = 'TODAY_ALLDAY_EXISTS',
  badPartOfDayToday = 'CANNOT_RESERVE_ANOTHER_PART_OF_DAY_TODAY',
  reservationsLimitReached = 'RESERVATIONS_LIMIT_REACHED',
}

export interface ReservationData {
  id: string
  email: string
  date: string
  partOfDay: PartOfDay
  cardReturned: boolean
}

export interface ReservationsResponse {
  data: ReservationData[]
}

export interface DefaultSettings {
  maxAllowedResDate: number
  hoursFrameReturnCard: number
  latestFeBreakingChangeVersion: string
}

// @ts-ignore
export interface ReservationRich extends ReservationData {
  date: DateTime | null
  timelineDate: {
    day: string
    month: string
  }
  partOfDay: {
    name: string
    range: string[]
  }
}

export interface AvailableSlots extends PartOfDayCount {
  date: string
  isAnyAvailable: boolean
  isMorningAvailable: boolean
  isAfternoonAvailable: boolean
  isAlldayAvailable: boolean
  values: PartOfDay[]
  displayValues: string[]
}

export interface SelectedSlotData {
  date: DateTime | string
  time: string
}

export interface User extends Models.Document {
  name: string
  registration: string
  status: boolean
  labels: string[]
  passwordUpdate: string
  email: string
  phone: string
  emailVerification: boolean
  phoneVerification: boolean
  prefs: object
  accessedAt: string
}

export interface StoreState {
  defaultSettings: DefaultSettings
  isUserLoggedIn: boolean
  reservations: Reservation[]
  historicalReservations: Reservation[]
  cardMissingReservations: Reservation[]
  user: User | null
}

export interface ParkingReservationSuccessEvent {
  email?: string
  date: Date
  partOfDay: PartOfDay
}
