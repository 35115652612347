<template>
  <f7-list media-list dividers-ios strong inset>
    <f7-list-item :title="formattedValues.date" subtitle="Dátum rezervácie" />
    <f7-list-item :title="formattedValues.time" subtitle="Čas rezervácie" />
  </f7-list>
  <f7-block>
    <f7-button large fill type="submit" @click="emit('submit')" bg-color="secondary">Potvrdiť rezerváciu</f7-button>
  </f7-block>
</template>

<script setup lang="ts">
import { inject } from 'vue'

const { formattedValues } = inject<any>('formData')

const emit = defineEmits(['submit'])
</script>
