import { Browser } from '@capacitor/browser'
import { CapacitorHttp } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import { Account, Client, Models, OAuthProvider } from 'appwrite'
import { getDevice } from 'framework7'
import { useStore } from 'framework7-vue'
import { DateTime } from 'luxon'
import { ofetch } from 'ofetch'

import Reservation from '../classes/parking/Reservation'
import { getAuthorizationToken } from './auth'
import { ToReservation } from './mappers'
import { AvailableSlots, DefaultSettings, PartOfDay, ReservationsResponse } from './types'
import { oauthScopes } from './utils'

const client = new Client()
const account = new Account(client)

client.setEndpoint('https://api.pelican.travel/v1').setProject('peliparking')

const device = getDevice()

const PARKING_PATH = device.cordova ? 'https://be-jobs.pelican.travel/api/pelikanec/v1/parking' : '/api/parking'
const ACCOUNT_PATH = device.cordova ? 'https://be-jobs.pelican.travel/api/pelikanec/v1/account' : '/api/account'

const processAppwriteRequest = async (url: string, method?: string, data?: object) => {
  const result = await CapacitorHttp.request({
    url: `https://api.pelican.travel/v1${url}`,
    method: method || 'GET',
    headers: {
      'X-Appwrite-Project': 'peliparking',
      'X-Appwrite-Response-Format': '1.6.0',
      'Content-Type': 'application/json',
    },
    data:
      method === 'POST' || method === 'PATCH'
        ? {
            ...(data && { ...data }),
          }
        : undefined,
  })

  const status = String(result.status)
  if (!status.startsWith('2')) {
    throw new Error(result.data.message)
  }
  return result.data
}

export const getSession = async (sessionId = 'current', isCordova: boolean) => {
  if (isCordova) {
    return await processAppwriteRequest(`/account/sessions/${sessionId}`)
  }

  return await account.getSession(sessionId)
}

export const createSession = async (userId: string, secret: string, isCordova: boolean) => {
  if (isCordova) {
    return await processAppwriteRequest(`/account/sessions/token`, 'POST', {
      userId,
      secret,
    })
  }

  return await account.createSession(userId, secret)
}

export const getAccount = async (isCordova: boolean): Promise<Models.User<any>> => {
  if (isCordova) {
    return await processAppwriteRequest('/account')
  }

  return await account.get()
}

export const createJWT = async (isCordova: boolean) => {
  if (isCordova) {
    return await processAppwriteRequest('/account/jwts', 'POST')
  }

  return await account.createJWT()
}

export const createEmailPasswordSession = async (email: string, password: string, isCordova: boolean) => {
  if (isCordova) {
    return await processAppwriteRequest('/account/sessions/email', 'POST', {
      email,
      password,
    })
  }

  return await account.createEmailPasswordSession(email, password)
}

export const createOAuth2Token = async (
  provider: OAuthProvider = OAuthProvider.Google,
  webAppUrl: string,
  isCordova: boolean,
) => {
  let backUrl = webAppUrl

  if (isCordova) {
    backUrl = 'callback-pelikanec://localhost/auth/oauth2/success'
    const scopesString = oauthScopes.join('&scopes[]=')

    return await Browser.open({
      url: `https://api.pelican.travel/v1/account/tokens/oauth2/${provider}?project=peliparking&success=${backUrl}&scopes[]=${scopesString}`,
      presentationStyle: 'popover',
    })
  }

  return account.createOAuth2Token(provider, backUrl, backUrl, oauthScopes)
}

export const updateSession = async (sessionId = 'current', isCordova: boolean) => {
  if (isCordova) {
    return await processAppwriteRequest(`/account/sessions/${sessionId}`, 'PATCH')
  }

  return await account.updateSession(sessionId)
}

export const deleteSession = async (sessionId = 'current', isCordova: boolean) => {
  if (isCordova) {
    return await processAppwriteRequest(`/account/sessions/${sessionId}`, 'DELETE')
  }

  return await account.deleteSession(sessionId)
}

export const getDefaultSettings = async (): Promise<DefaultSettings> => {
  return await ofetch(`${PARKING_PATH}/default-settings`, {
    headers: {
      Authorization: `Bearer ${await getAuthorizationToken()}`,
    },
  })
}

export const setProfilePrefs = async () => {
  return await ofetch(`${ACCOUNT_PATH}/profile-prefs`, {
    headers: {
      'Authorization': `Bearer ${await getAuthorizationToken()}`,
      'X-Session-Id': (await getSession('current', device.cordova)).$id,
    },
  })
}

export const getAvailableSlots = async (date: DateTime): Promise<AvailableSlots> => {
  return await ofetch(`${PARKING_PATH}/available-slots`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAuthorizationToken()}`,
    },
    body: { date: date.toISODate() },
  })
}

export const getReservations = async (
  specification: string = undefined,
  asc: boolean = true,
): Promise<Reservation[]> => {
  const reservations = await ofetch<ReservationsResponse>(`${PARKING_PATH}/reservations`, {
    headers: {
      'Authorization': `Bearer ${await getAuthorizationToken()}`,
      'X-Specification': specification,
    },
  })

  reservations.data.sort((a: { date: string }, b: { date: string }) => {
    const dateA = DateTime.fromISO(a.date)
    const dateB = DateTime.fromISO(b.date)

    if (dateA < dateB) return asc ? -1 : 1
    if (dateA > dateB) return asc ? 1 : -1
    return 0
  })

  return reservations.data.map((r) => new Reservation(ToReservation(r)))
}

export const returnCard = async (id: string) => {
  return await ofetch(`${PARKING_PATH}/reservation/${id}/return-card`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${await getAuthorizationToken()}`,
    },
  })
}

export const deleteReservation = async (id: string, isCardReturned: boolean) => {
  return await ofetch(`${PARKING_PATH}/reservation/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${await getAuthorizationToken()}`,
      'X-Card-Returned': String(isCardReturned),
    },
  })
}

export const reserveSlot = async (date: Date, partOfDay: PartOfDay): Promise<unknown> => {
  return await ofetch(`${PARKING_PATH}/reserve`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAuthorizationToken()}`,
    },
    body: {
      email: useStore('user').value.email,
      date,
      partOfDay,
    },
  })
}
