import { f7, useStore } from 'framework7-vue'
import { DateTime } from 'luxon'
import store from '../../../js/store'

import { getReservations, reserveSlot } from '../../composables/endpoints'
import { PartOfDay, ReservationRich } from '../../composables/types'
import Reservation from './Reservation'
import { notify, processError } from '../../composables/utils'
import { trackParkingReservationSuccess } from '../../composables/events'

export default class Reservations {
  constructor() {}

  static async #checkIfReservationExists(date: Date): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const luxonDate = DateTime.fromJSDate(date)
      const reservation: ReservationRich = Reservations.listUpcomingReservations().find((res: Reservation) =>
        res.date.hasSame(luxonDate, 'day'),
      )
      if (reservation) {
        f7.dialog.confirm(
          'Upozorňujeme Vás, že na tento deň už máte vytvorenú rezerváciu. Odoslaním formulára <b>prepíšete existujúcu rezerváciu</b>.',
          () => resolve(true),
          () => resolve(false),
        )
      } else {
        resolve(true)
      }
    })
  }

  static async makeReservation(date: Date, time: PartOfDay) {
    if (await this.#checkIfReservationExists(date)) {
      try {
        f7.dialog.preloader('Vytváram rezerváciu')
        await reserveSlot(date, time)

        notify('Rezervácia bola úspešne vytvorená', () => {
          f7.popup.close('.parking-reservation-form')
        })

        trackParkingReservationSuccess({ date, partOfDay: time })
        this.#getUpcomingReservations()
      } catch (error) {
        processError(error)
      } finally {
        f7.dialog.close()
      }
    }
  }

  static listUpcomingReservations() {
    return useStore('reservations').value
  }

  static listHistoricalReservations() {
    return useStore('historicalReservations').value
  }

  static async #getUpcomingReservations() {
    try {
      const reservations = await getReservations('upcoming')
      await store.dispatch('setReservations', reservations)
    } catch (e) {
      processError(e, 'Nepodarilo sa načítať naplánované rezervácie.')
    }
  }

  static async #getHistoricalReservations() {
    try {
      const reservations = await getReservations('historical', false)
      await store.dispatch('setHistoricalReservations', reservations)
    } catch (e) {
      processError(e, 'Nepodarilo sa načítať historické rezervácie.')
    }
  }

  static async #getReservationsWithMissingCard() {
    try {
      const reservations = await getReservations('missingCard')
      await store.dispatch('setCardMissingReservations', reservations)
    } catch (e) {
      processError(e, 'Nepodarilo sa načítať rezervácie s nevrátenou kartou.')
    }
  }

  static async fetchNewData() {
    await Promise.race([
      this.#getUpcomingReservations(),
      this.#getHistoricalReservations(),
      this.#getReservationsWithMissingCard(),
    ])
  }
}
