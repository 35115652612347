// Import Vue
import { createApp } from 'vue'

// Import Sentry for issue tracking
import * as Sentry from '@sentry/vue'

// Import Framework7
import Framework7 from 'framework7/lite-bundle'

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle'

// Import Framework7 Styles
import 'framework7/css/bundle'

// Import Icons and App Custom Styles
import '../css/app.scss'
import '../css/icons.css'

// Import App Component
import App from '../components/app.vue'

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue)

// Init App
const app = createApp(App)

// Register Framework7 Vue components
registerComponents(app)

Sentry.init({
  release: `${__APP_NAME__}@${__APP_VERSION__}`,
  environment: import.meta.env.MODE,
  app,
  dsn: 'https://972656ab9fd72763dcea47a2582412ad@o4507514363379712.ingest.de.sentry.io/4507514367770704',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.browserProfilingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: import.meta.env.PROD ? 0.5 : 1.0, //  Capture 100% of the transactions
  tracePropagationTargets: [/^\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: 1.0,
})

// Mount the app
app.mount('#app')
