import { DateTime } from 'luxon'
import { computed, ref } from 'vue'

import type { PartOfDay } from '../types'

export const useParkingReservationData = () => {
  const selectedDate = ref<Date[]>([])
  const selectedTime = ref<PartOfDay | null>(null)
  const formattedTime = ref<string>('')
  const step = ref<number>(1)
  const currentDate = ref(DateTime.now())

  const selectedDateWithStaticTimezone = computed(() => {
    return selectedDate.value.map((date) => {
      return DateTime.fromJSDate(date, { zone: 'system' })
        .setZone('Europe/Bratislava', { keepLocalTime: true })
        .toJSDate()
    })
  })

  const validation = ref({
    datePicker: false,
    timePicker: false,
  })

  const validateForm = (): boolean => {
    validation.value.datePicker = selectedDate.value.length === 0
    validation.value.timePicker = selectedTime.value === null

    return !Object.values(validation.value).some((value) => value)
  }

  const resetData = (): void => {
    selectedDate.value = []
    selectedTime.value = null
    formattedTime.value = ''
    step.value = 1

    validation.value.datePicker = false
    validation.value.timePicker = false
  }

  return {
    currentDate,
    step,
    selectedDate,
    selectedDateWithStaticTimezone,
    selectedTime,
    formattedTime,
    validation,
    validateForm,
    resetData,
  }
}
