<template>
  <div v-if="data.length" class="timeline">
    <div v-for="res in data" :key="res.id" class="timeline-item">
      <template v-if="!hideTimelineDate">
        <div class="timeline-item-date">
          {{ res.timelineDate.day }} <small>{{ res.timelineDate.month }}</small>
        </div>
        <div class="timeline-item-divider"></div>
      </template>
      <f7-list
        :class="[
          'timeline-item-content',
          {
            'timeline__no-swipeout': !isSwipeoutAllowed,
            'timeline__no-card-returned': !disableCardReturn && missingCardsReservations.find((r) => r.id === res.id),
          },
        ]"
        strong
        inset
        dividers-ios
        outline-ios
      >
        <f7-list-item :swipeout="isSwipeoutAllowed" @swipeout:delete="removeReservation(res)">
          <template #title>
            <div class="timeline-item-time">{{ res.partOfDay.range.join(' – ') }}</div>
            <div class="timeline-item-title">{{ res.partOfDay.name }}</div>
            <div
              v-if="!disableCardReturn && missingCardsReservations.find((r) => r.id === res.id)"
              class="timeline-item-text"
            >
              <small>Prekročili ste maximálny čas na vrátenie karty. Prosím, vráťte ju čo najskôr.</small>
            </div>
          </template>
          <f7-swipeout-actions right>
            <template v-if="!disableCardReturn">
              <f7-swipeout-button v-if="!res.cardReturned" color="orange" @click="returnCard(res)" close
                >Vrátiť kartu</f7-swipeout-button
              >
              <f7-swipeout-button v-else class="cursor-not-allowed">Karta už bola vrátená</f7-swipeout-button>
            </template>
            <f7-swipeout-button v-if="!disableDelete" delete :confirm-text="beforeDeleteText(res)" close>
              Vymazať
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </f7-list>
    </div>
  </div>
  <f7-block strong inset v-else-if="noDataText">
    <p class="text-align-center">{{ noDataText }}</p>
  </f7-block>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { f7, useStore } from 'framework7-vue'

import Reservation from '../../assets/classes/parking/Reservation'

defineProps({
  data: {
    type: Array as PropType<Reservation[]>,
    required: true,
  },
  noDataText: {
    type: String,
    default: '',
  },
  isSwipeoutAllowed: {
    type: Boolean,
    default: true,
  },
  hideTimelineDate: {
    type: Boolean,
    default: false,
  },
  disableCardReturn: {
    type: Boolean,
    default: false,
  },
  disableDelete: {
    type: Boolean,
    default: false,
  },
})

const missingCardsReservations = useStore('cardMissingReservations') as Reservation[]

const returnCard = (res: Reservation) => {
  const date = res.date?.toFormat('dd. MM. yyyy')
  f7.dialog.confirm(
    `Naozaj ste vrátili kartu, ktorú ste použili pri parkovaní <b>${date}</b> (<b>${res.partOfDay.name?.toLowerCase()}</b>)? Pokračovaním potvrdzujete vrátenie karty.`,
    () => res.returnCard(),
  )
}

const beforeDeleteText = (res: Reservation) => {
  const date = res.date?.toFormat('dd. MM. yyyy')
  return `Chystáte sa odstrániť rezerváciu na <b>${date}</b> (<b>${res.partOfDay.name?.toLowerCase()}</b>). Pokračovaním súhlasíte s vymazaním.`
}

const removeReservation = (res: Reservation) => {
  f7.dialog
    .create({
      title: 'Zrušenie rezervácie',
      text: 'Stihli ste si už vyzdvihnúť parkovaciu kartu?',
      buttons: [
        {
          text: 'Nie',
          strong: false,
          onClick: () => res.setCardReturn(true) && res.removeReservation(),
        },
        {
          text: 'Áno',
          strong: false,
          onClick: () => res.setCardReturn(false) && res.removeReservation(),
        },
      ],
    })
    .open()
}
</script>

<style scoped>
.timeline__no-swipeout :deep(ul) {
  background-color: #e3e3e3;
}

.timeline__no-card-returned :deep(ul) {
  background-color: #f8d7da;
}

.timeline__no-card-returned small {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
</style>
